<template>
  <div
    class="dit:max-h-[60px] relative aspect-square overflow-hidden rounded-lg bg-white">
    <!-- if live deferred, show radio cover -->
    <div
      v-if="isLive && isLiveDeferred && radioCover?.src"
      class="p-2">
      <div
        v-if="!(radioCoverLoaded || radioCoverElement?.$el.complete)"
        role="status"
        :class="[
          'aspect-square animate-pulse rounded-lg bg-neutral-600',
          ,
          { '!h-full !w-full': fullSize }
        ]"
        :style="{ width: coverWidth + 'px', height: coverWidth + 'px' }" />

      <NuxtImg
        v-show="radioCoverLoaded || radioCoverElement?.$el.complete"
        ref="radioCoverElement"
        format="webp"
        :sizes="coverSizes"
        :src="radioCover?.src"
        :alt="radioCover?.alt"
        provider="directus"
        :width="coverWidth"
        :height="coverWidth"
        :class="['aspect-square object-contain', { 'h-full w-full': fullSize }]"
        @load="radioCoverLoaded = true" />
    </div>

    <!-- otherwise, program cover -->
    <div v-else>
      <!-- two images in order to make blending transitions -->
      <NuxtImg
        :key="coverBackImg"
        ref="coverBack"
        format="webp"
        :sizes="coverSizes"
        :src="coverBackImg"
        :alt="cover.alt"
        :provider="cover.src && cover.provider"
        :width="coverWidth"
        :height="coverWidth"
        :class="['aspect-square object-cover', { 'h-full w-full': fullSize }]"
        @load="
          () => {
            onCoverBackLoaded(coverFront.$el, () => {
              coverFrontImg = coverBackImg
            })
            loaded = true
          }
        " />
      <NuxtImg
        :key="coverFrontImg"
        ref="coverFront"
        format="webp"
        :src="coverFrontImg"
        :alt="cover.alt"
        :provider="cover.src && cover.provider"
        :width="coverWidth"
        :height="coverWidth"
        :class="[
          'absolute left-0 top-0 aspect-square object-contain bg-neutral-400',
          { 'h-full w-full': fullSize }
        ]"
        @load="
          () => {
            onCoverFrontLoaded(coverFront.$el)
            loaded = true
          }
        " />
    </div>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash'
import { NuxtConfigSchema } from 'nuxt/schema'

withDefaults(
  defineProps<{
    coverWidth?: number
    coverSizes?: string
    fullSize?: boolean
  }>(),
  {
    coverWidth: 300,
    coverSizes: 'sm:100vw md:400px lg:800px',
    fullSize: false
  }
)

const { cover, radio, isLive, isLiveDeferred } = usePlayerState()

// cover image loading state
const loaded = ref(false)

/**
 * Handle cover transitions
 */
const coverBack = ref()
const coverFront = ref()
const coverBackImg = ref(useImagePlaceholder('square'))
const coverFrontImg = ref(useImagePlaceholder('square'))

const { onCoverBackLoaded, onCoverFrontLoaded } = useImageBlending()

const setCover = () => {
  if (!cover.value.src) {
    return
  }

  coverBackImg.value = cover.value.src
}

onMounted(setCover)
watch(cover, setCover, { deep: true })

const { $radios } = useNuxtApp()
const config = useRuntimeConfig()

const radioCoverElement = ref()
const radioCoverLoaded = ref(false)

const radioCover = computed(() => {
  return $radios.find((r) => r.key === radio.value)?.image
})
</script>
