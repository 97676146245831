import Hls from 'hls.js/dist/hls.min'
import usePlayerMeta from '@/plugins/player/meta'
import usePlayerBindings from '@/plugins/player/bindings'
import { DateTime } from 'luxon'

export default function usePlayerActions(playerElement: Ref<HTMLAudioElement>) {
  const config = useRuntimeConfig()
  const { $radios } = useNuxtApp()

  const {
    isPlaying,
    isLive,
    title,
    cover,
    subtitle,
    radio,
    progress,
    isProgram,
    isLoading,
    radioLabel,
    isEnabled
  } = usePlayerState()

  const { currentProgram } = usePlayerMeta()
  const { onTimeUpdate } = usePlayerBindings(playerElement)

  let hls: any
  let firstLoad = true

  const createAudioElement = async (audioSource: string, autoplay = false) => {
    // reset everything from the player
    isLoading.value = true
    isPlaying.value = false
    title.value = ''
    cover.value = { src: null, alt: '' }
    isLive.value = false
    isProgram.value = false
    currentProgram.value = null
    progress.value = 0

    // destroy the old player
    if (playerElement.value !== undefined) {
      playerElement.value.pause()
      playerElement.value.remove()

      if (hls) hls.destroy()
    }

    // create a new player element
    playerElement.value = document.createElement('audio')

    // bind events to it
    playerElement.value.addEventListener('timeupdate', onTimeUpdate)
    playerElement.value.addEventListener('seeked', onTimeUpdate)
    playerElement.value.addEventListener('ended', () => {
      isPlaying.value = false
    })

    playerElement.value.addEventListener('error', () => {
      isPlaying.value = false
      console.log('Player error')
    })

    // If the audioSource is an hls stream we use hls.js lib
    if (Hls.isSupported() && audioSource.includes('m3u8')) {
      hls = new Hls({
        liveSyncDurationCount: 6
      })
      hls.loadSource(audioSource)
      hls.attachMedia(playerElement.value)
      isLive.value = true
      hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) { })
      hls.on(Hls.Events.ERROR, function (event, data) { })
      playerElement.value.addEventListener('seeked', onTimeUpdate)
    } else {
      playerElement.value.src = audioSource
    }

    if (autoplay)
      playerElement.value.addEventListener('loadeddata', () => {
        isPlaying.value = true
      })

    isEnabled.value = true
    isLoading.value = false
  }

  const updateRadioStream = async (newRadioValue: string | null | false) => {
    if (newRadioValue == null || newRadioValue == false) return

    createAudioElement(
      [
        config.public.hlsBaseUrl,
        'hls',
        newRadioValue.toLowerCase(),
        'live.m3u8'
      ].join('/'),
      firstLoad
    )

    let currentRadio = $radios.find((r: any) => r.key == newRadioValue)
    subtitle.value = currentRadio.label
    radioLabel.value = currentRadio.label
    firstLoad = true
  }

  watch(radio, updateRadioStream)

  return { createAudioElement }
}
