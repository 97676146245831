import voca from 'voca'

interface TranslateOptions {
  lang?: string
  stripHTMLTags?: boolean
  limitChar?: number
}

export const translateItem = (
  item,
  options = { lang: 'fr', stripHTMLTags: false, limitChar: 0 }
) => {
  const { lang, stripHTMLTags, limitChar } = options
  // If there's no translation we return the item
  if (!item?.translations) return { ...item }

  if (item.translations.length === 0) return { ...item }

  let fallbackTranslation = item.translations.reduce((acc, current) => {
    let result = { ...acc }

    for (const prop in current) {
      if (['id', 'program_id', 'languages_code'].includes(prop)) continue

      if (current[prop] == null) continue

      result[prop] = current[prop]
    }
    return result
  })

  fallbackTranslation = item.translations.find((el) =>
    (el?.languages_code || el?.languages_id || []).includes('fr')
  )

  const currentLanguageContent = item.translations.find((el) =>
    (el.languages_code || el.languages_id || []).includes(lang)
  )

  if (currentLanguageContent)
    Object.keys(currentLanguageContent).forEach((key) => {
      if (
        currentLanguageContent[key] === null ||
        currentLanguageContent[key] === ''
      ) {
        delete currentLanguageContent[key]
      }
    })

  // We merge our data
  let foundTranslation = { ...fallbackTranslation, ...currentLanguageContent }

  // Then we build a brand new object that has it's translations props at root
  const clone = { ...foundTranslation }
  delete clone.id, delete clone.program_id, delete clone.languages_code

  if (stripHTMLTags) {
    Object.keys(clone).forEach((el) => {
      clone[el] = voca.stripTags(clone[el])
    })
  }

  if (limitChar > 0) {
    Object.keys(clone).forEach((el) => {
      clone[el] = voca.first(clone[el], limitChar)
    })
  }
  return { ...item, ...clone }
}

export const translateItems = (
  items: [],
  { lang = 'fr', stripHTMLTags = false, limitChar = 0 }: TranslateOptions
) => {
  return items.map((item) =>
    translateItem(item, { lang, stripHTMLTags, limitChar } as TranslateOptions)
  )
}

export function useTranslator() {
  const { locale } = useI18n()

  return {
    translate: (item: any) => {
      return translateItem(item, {
        lang: locale.value,
        stripHTMLTags: false,
        limitChar: 0
      })
    },
    translateDay: (day: number) => {
      const days = {
        en: [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
          'Sunday'
        ],
        fr: [
          'Lundi',
          'Mardi',
          'Mercredi',
          'Jeudi',
          'Vendredi',
          'Samedi',
          'Dimanche'
        ],
        br: ['Lun', 'Meurzh', "Merch'her", 'Yaou', 'Gwener', 'Sadorn', 'Sul'],
        ga: ['Lin.', 'Mar.', 'Méc.', 'Jeû.', 'Ven.', 'Sam.', 'Dim.']
      }
      return days[locale.value || 'fr'][day - 1] || 'Erreur'
    },
    translateMonth: (month: number) => {
      const months = {
        en: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ],
        fr: [
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Mai',
          'Juin',
          'Juillet',
          'Août',
          'Septembre',
          'Octobre',
          'Novembre',
          'Décembre'
        ],
        br: [
          'Genver',
          "C'hwevrer",
          'Meurzh',
          'Ebrel',
          'Mae',
          'Even',
          'Gouere',
          'Eost',
          'Gwengolo',
          'Here',
          'Du',
          'Kerzu'
        ],
        ga: [
          'Janviër',
          'Fev·riër',
          'Marr',
          'Avri',
          'Mae',
          'Juin',
          'Julhèt',
          'Aot',
          'Septenb',
          'Octob',
          'Novenb',
          'Deçenb'
        ]
      }
      return months[locale.value || 'fr'][month - 1]
    }
  }
}
