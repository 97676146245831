import { default as _91episode_93gkRdnFnSJCMeta } from "/workspace/pages/[radio]/[program]/[episode].vue?macro=true";
import { default as indexvCBHAVTlRdMeta } from "/workspace/pages/[radio]/[program]/index.vue?macro=true";
import { default as episodes7Z0TUhAWIgMeta } from "/workspace/pages/[radio]/episodes.vue?macro=true";
import { default as indexeCstuxaPCzMeta } from "/workspace/pages/[radio]/index.vue?macro=true";
import { default as programsJutpzYzdKMMeta } from "/workspace/pages/[radio]/programs.vue?macro=true";
import { default as aboutIYttGxXltYMeta } from "/workspace/pages/about.vue?macro=true";
import { default as contact8zxLAXYQX4Meta } from "/workspace/pages/contact.vue?macro=true";
import { default as _91id_93uxJjgXSuDRMeta } from "/workspace/pages/events/[id].vue?macro=true";
import { default as indextReQqWaBrAMeta } from "/workspace/pages/events/index.vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as legalzeOtcHH9twMeta } from "/workspace/pages/legal.vue?macro=true";
import { default as _91id_93z4WGN65YCKMeta } from "/workspace/pages/news/[id].vue?macro=true";
import { default as indexYTGC2mQSXYMeta } from "/workspace/pages/news/index.vue?macro=true";
import { default as partner_45radiosnP956sfu6nMeta } from "/workspace/pages/partner-radios.vue?macro=true";
import { default as player_45debug7aeCiAkiBdMeta } from "/workspace/pages/player-debug.vue?macro=true";
import { default as _91category_93lFwTm7hna0Meta } from "/workspace/pages/programs/[category].vue?macro=true";
import { default as indexoo0aTMUTRHMeta } from "/workspace/pages/programs/index.vue?macro=true";
import { default as schedule8R2ap9OlOoMeta } from "/workspace/pages/schedule.vue?macro=true";
import { default as searchQVlILEhc6OMeta } from "/workspace/pages/search.vue?macro=true";
import { default as sponsorsKiigEKYNN5Meta } from "/workspace/pages/sponsors.vue?macro=true";
import { default as what_45songQ9VUjSQ32XMeta } from "/workspace/pages/what-song.vue?macro=true";
export default [
  {
    name: _91episode_93gkRdnFnSJCMeta?.name ?? "radio-program-episode",
    path: _91episode_93gkRdnFnSJCMeta?.path ?? "/:radio()/:program()/:episode()",
    meta: _91episode_93gkRdnFnSJCMeta || {},
    alias: _91episode_93gkRdnFnSJCMeta?.alias || [],
    redirect: _91episode_93gkRdnFnSJCMeta?.redirect || undefined,
    component: () => import("/workspace/pages/[radio]/[program]/[episode].vue").then(m => m.default || m)
  },
  {
    name: _91episode_93gkRdnFnSJCMeta?.name ?? "radio-program-episode___fr",
    path: _91episode_93gkRdnFnSJCMeta?.path ?? "/fr/:radio()/:program()/:episode()",
    meta: _91episode_93gkRdnFnSJCMeta || {},
    alias: _91episode_93gkRdnFnSJCMeta?.alias || [],
    redirect: _91episode_93gkRdnFnSJCMeta?.redirect || undefined,
    component: () => import("/workspace/pages/[radio]/[program]/[episode].vue").then(m => m.default || m)
  },
  {
    name: _91episode_93gkRdnFnSJCMeta?.name ?? "radio-program-episode___br",
    path: _91episode_93gkRdnFnSJCMeta?.path ?? "/br/:radio()/:program()/:episode()",
    meta: _91episode_93gkRdnFnSJCMeta || {},
    alias: _91episode_93gkRdnFnSJCMeta?.alias || [],
    redirect: _91episode_93gkRdnFnSJCMeta?.redirect || undefined,
    component: () => import("/workspace/pages/[radio]/[program]/[episode].vue").then(m => m.default || m)
  },
  {
    name: _91episode_93gkRdnFnSJCMeta?.name ?? "radio-program-episode___ga",
    path: _91episode_93gkRdnFnSJCMeta?.path ?? "/ga/:radio()/:program()/:episode()",
    meta: _91episode_93gkRdnFnSJCMeta || {},
    alias: _91episode_93gkRdnFnSJCMeta?.alias || [],
    redirect: _91episode_93gkRdnFnSJCMeta?.redirect || undefined,
    component: () => import("/workspace/pages/[radio]/[program]/[episode].vue").then(m => m.default || m)
  },
  {
    name: _91episode_93gkRdnFnSJCMeta?.name ?? "radio-program-episode___en",
    path: _91episode_93gkRdnFnSJCMeta?.path ?? "/en/:radio()/:program()/:episode()",
    meta: _91episode_93gkRdnFnSJCMeta || {},
    alias: _91episode_93gkRdnFnSJCMeta?.alias || [],
    redirect: _91episode_93gkRdnFnSJCMeta?.redirect || undefined,
    component: () => import("/workspace/pages/[radio]/[program]/[episode].vue").then(m => m.default || m)
  },
  {
    name: indexvCBHAVTlRdMeta?.name ?? "radio-program",
    path: indexvCBHAVTlRdMeta?.path ?? "/:radio()/:program()",
    meta: indexvCBHAVTlRdMeta || {},
    alias: indexvCBHAVTlRdMeta?.alias || [],
    redirect: indexvCBHAVTlRdMeta?.redirect || undefined,
    component: () => import("/workspace/pages/[radio]/[program]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvCBHAVTlRdMeta?.name ?? "radio-program___fr",
    path: indexvCBHAVTlRdMeta?.path ?? "/fr/:radio()/:program()",
    meta: indexvCBHAVTlRdMeta || {},
    alias: indexvCBHAVTlRdMeta?.alias || [],
    redirect: indexvCBHAVTlRdMeta?.redirect || undefined,
    component: () => import("/workspace/pages/[radio]/[program]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvCBHAVTlRdMeta?.name ?? "radio-program___br",
    path: indexvCBHAVTlRdMeta?.path ?? "/br/:radio()/:program()",
    meta: indexvCBHAVTlRdMeta || {},
    alias: indexvCBHAVTlRdMeta?.alias || [],
    redirect: indexvCBHAVTlRdMeta?.redirect || undefined,
    component: () => import("/workspace/pages/[radio]/[program]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvCBHAVTlRdMeta?.name ?? "radio-program___ga",
    path: indexvCBHAVTlRdMeta?.path ?? "/ga/:radio()/:program()",
    meta: indexvCBHAVTlRdMeta || {},
    alias: indexvCBHAVTlRdMeta?.alias || [],
    redirect: indexvCBHAVTlRdMeta?.redirect || undefined,
    component: () => import("/workspace/pages/[radio]/[program]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvCBHAVTlRdMeta?.name ?? "radio-program___en",
    path: indexvCBHAVTlRdMeta?.path ?? "/en/:radio()/:program()",
    meta: indexvCBHAVTlRdMeta || {},
    alias: indexvCBHAVTlRdMeta?.alias || [],
    redirect: indexvCBHAVTlRdMeta?.redirect || undefined,
    component: () => import("/workspace/pages/[radio]/[program]/index.vue").then(m => m.default || m)
  },
  {
    name: episodes7Z0TUhAWIgMeta?.name ?? "radio-episodes",
    path: episodes7Z0TUhAWIgMeta?.path ?? "/:radio()/episodes",
    meta: episodes7Z0TUhAWIgMeta || {},
    alias: episodes7Z0TUhAWIgMeta?.alias || [],
    redirect: episodes7Z0TUhAWIgMeta?.redirect || undefined,
    component: () => import("/workspace/pages/[radio]/episodes.vue").then(m => m.default || m)
  },
  {
    name: episodes7Z0TUhAWIgMeta?.name ?? "radio-episodes___fr",
    path: episodes7Z0TUhAWIgMeta?.path ?? "/fr/:radio()/episodes",
    meta: episodes7Z0TUhAWIgMeta || {},
    alias: episodes7Z0TUhAWIgMeta?.alias || [],
    redirect: episodes7Z0TUhAWIgMeta?.redirect || undefined,
    component: () => import("/workspace/pages/[radio]/episodes.vue").then(m => m.default || m)
  },
  {
    name: episodes7Z0TUhAWIgMeta?.name ?? "radio-episodes___br",
    path: episodes7Z0TUhAWIgMeta?.path ?? "/br/:radio()/episodes",
    meta: episodes7Z0TUhAWIgMeta || {},
    alias: episodes7Z0TUhAWIgMeta?.alias || [],
    redirect: episodes7Z0TUhAWIgMeta?.redirect || undefined,
    component: () => import("/workspace/pages/[radio]/episodes.vue").then(m => m.default || m)
  },
  {
    name: episodes7Z0TUhAWIgMeta?.name ?? "radio-episodes___ga",
    path: episodes7Z0TUhAWIgMeta?.path ?? "/ga/:radio()/episodes",
    meta: episodes7Z0TUhAWIgMeta || {},
    alias: episodes7Z0TUhAWIgMeta?.alias || [],
    redirect: episodes7Z0TUhAWIgMeta?.redirect || undefined,
    component: () => import("/workspace/pages/[radio]/episodes.vue").then(m => m.default || m)
  },
  {
    name: episodes7Z0TUhAWIgMeta?.name ?? "radio-episodes___en",
    path: episodes7Z0TUhAWIgMeta?.path ?? "/en/:radio()/episodes",
    meta: episodes7Z0TUhAWIgMeta || {},
    alias: episodes7Z0TUhAWIgMeta?.alias || [],
    redirect: episodes7Z0TUhAWIgMeta?.redirect || undefined,
    component: () => import("/workspace/pages/[radio]/episodes.vue").then(m => m.default || m)
  },
  {
    name: indexeCstuxaPCzMeta?.name ?? "radio",
    path: indexeCstuxaPCzMeta?.path ?? "/:radio()",
    meta: indexeCstuxaPCzMeta || {},
    alias: indexeCstuxaPCzMeta?.alias || [],
    redirect: indexeCstuxaPCzMeta?.redirect || undefined,
    component: () => import("/workspace/pages/[radio]/index.vue").then(m => m.default || m)
  },
  {
    name: indexeCstuxaPCzMeta?.name ?? "radio___fr",
    path: indexeCstuxaPCzMeta?.path ?? "/fr/:radio()",
    meta: indexeCstuxaPCzMeta || {},
    alias: indexeCstuxaPCzMeta?.alias || [],
    redirect: indexeCstuxaPCzMeta?.redirect || undefined,
    component: () => import("/workspace/pages/[radio]/index.vue").then(m => m.default || m)
  },
  {
    name: indexeCstuxaPCzMeta?.name ?? "radio___br",
    path: indexeCstuxaPCzMeta?.path ?? "/br/:radio()",
    meta: indexeCstuxaPCzMeta || {},
    alias: indexeCstuxaPCzMeta?.alias || [],
    redirect: indexeCstuxaPCzMeta?.redirect || undefined,
    component: () => import("/workspace/pages/[radio]/index.vue").then(m => m.default || m)
  },
  {
    name: indexeCstuxaPCzMeta?.name ?? "radio___ga",
    path: indexeCstuxaPCzMeta?.path ?? "/ga/:radio()",
    meta: indexeCstuxaPCzMeta || {},
    alias: indexeCstuxaPCzMeta?.alias || [],
    redirect: indexeCstuxaPCzMeta?.redirect || undefined,
    component: () => import("/workspace/pages/[radio]/index.vue").then(m => m.default || m)
  },
  {
    name: indexeCstuxaPCzMeta?.name ?? "radio___en",
    path: indexeCstuxaPCzMeta?.path ?? "/en/:radio()",
    meta: indexeCstuxaPCzMeta || {},
    alias: indexeCstuxaPCzMeta?.alias || [],
    redirect: indexeCstuxaPCzMeta?.redirect || undefined,
    component: () => import("/workspace/pages/[radio]/index.vue").then(m => m.default || m)
  },
  {
    name: programsJutpzYzdKMMeta?.name ?? "radio-programs",
    path: programsJutpzYzdKMMeta?.path ?? "/:radio()/programs",
    meta: programsJutpzYzdKMMeta || {},
    alias: programsJutpzYzdKMMeta?.alias || [],
    redirect: programsJutpzYzdKMMeta?.redirect || undefined,
    component: () => import("/workspace/pages/[radio]/programs.vue").then(m => m.default || m)
  },
  {
    name: programsJutpzYzdKMMeta?.name ?? "radio-programs___fr",
    path: programsJutpzYzdKMMeta?.path ?? "/fr/:radio/emissions",
    meta: programsJutpzYzdKMMeta || {},
    alias: programsJutpzYzdKMMeta?.alias || [],
    redirect: programsJutpzYzdKMMeta?.redirect || undefined,
    component: () => import("/workspace/pages/[radio]/programs.vue").then(m => m.default || m)
  },
  {
    name: programsJutpzYzdKMMeta?.name ?? "radio-programs___br",
    path: programsJutpzYzdKMMeta?.path ?? "/br/:radio/emissions",
    meta: programsJutpzYzdKMMeta || {},
    alias: programsJutpzYzdKMMeta?.alias || [],
    redirect: programsJutpzYzdKMMeta?.redirect || undefined,
    component: () => import("/workspace/pages/[radio]/programs.vue").then(m => m.default || m)
  },
  {
    name: programsJutpzYzdKMMeta?.name ?? "radio-programs___ga",
    path: programsJutpzYzdKMMeta?.path ?? "/ga/:radio/emissions",
    meta: programsJutpzYzdKMMeta || {},
    alias: programsJutpzYzdKMMeta?.alias || [],
    redirect: programsJutpzYzdKMMeta?.redirect || undefined,
    component: () => import("/workspace/pages/[radio]/programs.vue").then(m => m.default || m)
  },
  {
    name: programsJutpzYzdKMMeta?.name ?? "radio-programs___en",
    path: programsJutpzYzdKMMeta?.path ?? "/en/:radio/emissions",
    meta: programsJutpzYzdKMMeta || {},
    alias: programsJutpzYzdKMMeta?.alias || [],
    redirect: programsJutpzYzdKMMeta?.redirect || undefined,
    component: () => import("/workspace/pages/[radio]/programs.vue").then(m => m.default || m)
  },
  {
    name: aboutIYttGxXltYMeta?.name ?? "about",
    path: aboutIYttGxXltYMeta?.path ?? "/about",
    meta: aboutIYttGxXltYMeta || {},
    alias: aboutIYttGxXltYMeta?.alias || [],
    redirect: aboutIYttGxXltYMeta?.redirect || undefined,
    component: () => import("/workspace/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutIYttGxXltYMeta?.name ?? "about___fr",
    path: aboutIYttGxXltYMeta?.path ?? "/fr/a-propos",
    meta: aboutIYttGxXltYMeta || {},
    alias: aboutIYttGxXltYMeta?.alias || [],
    redirect: aboutIYttGxXltYMeta?.redirect || undefined,
    component: () => import("/workspace/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutIYttGxXltYMeta?.name ?? "about___br",
    path: aboutIYttGxXltYMeta?.path ?? "/br/a-propos",
    meta: aboutIYttGxXltYMeta || {},
    alias: aboutIYttGxXltYMeta?.alias || [],
    redirect: aboutIYttGxXltYMeta?.redirect || undefined,
    component: () => import("/workspace/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutIYttGxXltYMeta?.name ?? "about___ga",
    path: aboutIYttGxXltYMeta?.path ?? "/ga/a-propos",
    meta: aboutIYttGxXltYMeta || {},
    alias: aboutIYttGxXltYMeta?.alias || [],
    redirect: aboutIYttGxXltYMeta?.redirect || undefined,
    component: () => import("/workspace/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutIYttGxXltYMeta?.name ?? "about___en",
    path: aboutIYttGxXltYMeta?.path ?? "/en/a-propos",
    meta: aboutIYttGxXltYMeta || {},
    alias: aboutIYttGxXltYMeta?.alias || [],
    redirect: aboutIYttGxXltYMeta?.redirect || undefined,
    component: () => import("/workspace/pages/about.vue").then(m => m.default || m)
  },
  {
    name: contact8zxLAXYQX4Meta?.name ?? "contact",
    path: contact8zxLAXYQX4Meta?.path ?? "/contact",
    meta: contact8zxLAXYQX4Meta || {},
    alias: contact8zxLAXYQX4Meta?.alias || [],
    redirect: contact8zxLAXYQX4Meta?.redirect || undefined,
    component: () => import("/workspace/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contact8zxLAXYQX4Meta?.name ?? "contact___fr",
    path: contact8zxLAXYQX4Meta?.path ?? "/fr/contact",
    meta: contact8zxLAXYQX4Meta || {},
    alias: contact8zxLAXYQX4Meta?.alias || [],
    redirect: contact8zxLAXYQX4Meta?.redirect || undefined,
    component: () => import("/workspace/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contact8zxLAXYQX4Meta?.name ?? "contact___br",
    path: contact8zxLAXYQX4Meta?.path ?? "/br/contact",
    meta: contact8zxLAXYQX4Meta || {},
    alias: contact8zxLAXYQX4Meta?.alias || [],
    redirect: contact8zxLAXYQX4Meta?.redirect || undefined,
    component: () => import("/workspace/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contact8zxLAXYQX4Meta?.name ?? "contact___ga",
    path: contact8zxLAXYQX4Meta?.path ?? "/ga/contact",
    meta: contact8zxLAXYQX4Meta || {},
    alias: contact8zxLAXYQX4Meta?.alias || [],
    redirect: contact8zxLAXYQX4Meta?.redirect || undefined,
    component: () => import("/workspace/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contact8zxLAXYQX4Meta?.name ?? "contact___en",
    path: contact8zxLAXYQX4Meta?.path ?? "/en/contact",
    meta: contact8zxLAXYQX4Meta || {},
    alias: contact8zxLAXYQX4Meta?.alias || [],
    redirect: contact8zxLAXYQX4Meta?.redirect || undefined,
    component: () => import("/workspace/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _91id_93uxJjgXSuDRMeta?.name ?? "events-id",
    path: _91id_93uxJjgXSuDRMeta?.path ?? "/events/:id()",
    meta: _91id_93uxJjgXSuDRMeta || {},
    alias: _91id_93uxJjgXSuDRMeta?.alias || [],
    redirect: _91id_93uxJjgXSuDRMeta?.redirect || undefined,
    component: () => import("/workspace/pages/events/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93uxJjgXSuDRMeta?.name ?? "events-id___fr",
    path: _91id_93uxJjgXSuDRMeta?.path ?? "/fr/evenement/:id",
    meta: _91id_93uxJjgXSuDRMeta || {},
    alias: _91id_93uxJjgXSuDRMeta?.alias || [],
    redirect: _91id_93uxJjgXSuDRMeta?.redirect || undefined,
    component: () => import("/workspace/pages/events/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93uxJjgXSuDRMeta?.name ?? "events-id___br",
    path: _91id_93uxJjgXSuDRMeta?.path ?? "/br/evenement/:id",
    meta: _91id_93uxJjgXSuDRMeta || {},
    alias: _91id_93uxJjgXSuDRMeta?.alias || [],
    redirect: _91id_93uxJjgXSuDRMeta?.redirect || undefined,
    component: () => import("/workspace/pages/events/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93uxJjgXSuDRMeta?.name ?? "events-id___ga",
    path: _91id_93uxJjgXSuDRMeta?.path ?? "/ga/evenement/:id",
    meta: _91id_93uxJjgXSuDRMeta || {},
    alias: _91id_93uxJjgXSuDRMeta?.alias || [],
    redirect: _91id_93uxJjgXSuDRMeta?.redirect || undefined,
    component: () => import("/workspace/pages/events/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93uxJjgXSuDRMeta?.name ?? "events-id___en",
    path: _91id_93uxJjgXSuDRMeta?.path ?? "/en/evenement/:id",
    meta: _91id_93uxJjgXSuDRMeta || {},
    alias: _91id_93uxJjgXSuDRMeta?.alias || [],
    redirect: _91id_93uxJjgXSuDRMeta?.redirect || undefined,
    component: () => import("/workspace/pages/events/[id].vue").then(m => m.default || m)
  },
  {
    name: indextReQqWaBrAMeta?.name ?? "events",
    path: indextReQqWaBrAMeta?.path ?? "/events",
    meta: indextReQqWaBrAMeta || {},
    alias: indextReQqWaBrAMeta?.alias || [],
    redirect: indextReQqWaBrAMeta?.redirect || undefined,
    component: () => import("/workspace/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indextReQqWaBrAMeta?.name ?? "events___fr",
    path: indextReQqWaBrAMeta?.path ?? "/fr/evenements",
    meta: indextReQqWaBrAMeta || {},
    alias: indextReQqWaBrAMeta?.alias || [],
    redirect: indextReQqWaBrAMeta?.redirect || undefined,
    component: () => import("/workspace/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indextReQqWaBrAMeta?.name ?? "events___br",
    path: indextReQqWaBrAMeta?.path ?? "/br/evenements",
    meta: indextReQqWaBrAMeta || {},
    alias: indextReQqWaBrAMeta?.alias || [],
    redirect: indextReQqWaBrAMeta?.redirect || undefined,
    component: () => import("/workspace/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indextReQqWaBrAMeta?.name ?? "events___ga",
    path: indextReQqWaBrAMeta?.path ?? "/ga/evenements",
    meta: indextReQqWaBrAMeta || {},
    alias: indextReQqWaBrAMeta?.alias || [],
    redirect: indextReQqWaBrAMeta?.redirect || undefined,
    component: () => import("/workspace/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indextReQqWaBrAMeta?.name ?? "events___en",
    path: indextReQqWaBrAMeta?.path ?? "/en/evenements",
    meta: indextReQqWaBrAMeta || {},
    alias: indextReQqWaBrAMeta?.alias || [],
    redirect: indextReQqWaBrAMeta?.redirect || undefined,
    component: () => import("/workspace/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: index9S03gOyIVcMeta?.name ?? "index",
    path: index9S03gOyIVcMeta?.path ?? "/",
    meta: index9S03gOyIVcMeta || {},
    alias: index9S03gOyIVcMeta?.alias || [],
    redirect: index9S03gOyIVcMeta?.redirect || undefined,
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index9S03gOyIVcMeta?.name ?? "index___fr",
    path: index9S03gOyIVcMeta?.path ?? "/fr",
    meta: index9S03gOyIVcMeta || {},
    alias: index9S03gOyIVcMeta?.alias || [],
    redirect: index9S03gOyIVcMeta?.redirect || undefined,
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index9S03gOyIVcMeta?.name ?? "index___br",
    path: index9S03gOyIVcMeta?.path ?? "/br",
    meta: index9S03gOyIVcMeta || {},
    alias: index9S03gOyIVcMeta?.alias || [],
    redirect: index9S03gOyIVcMeta?.redirect || undefined,
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index9S03gOyIVcMeta?.name ?? "index___ga",
    path: index9S03gOyIVcMeta?.path ?? "/ga",
    meta: index9S03gOyIVcMeta || {},
    alias: index9S03gOyIVcMeta?.alias || [],
    redirect: index9S03gOyIVcMeta?.redirect || undefined,
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index9S03gOyIVcMeta?.name ?? "index___en",
    path: index9S03gOyIVcMeta?.path ?? "/en",
    meta: index9S03gOyIVcMeta || {},
    alias: index9S03gOyIVcMeta?.alias || [],
    redirect: index9S03gOyIVcMeta?.redirect || undefined,
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: legalzeOtcHH9twMeta?.name ?? "legal",
    path: legalzeOtcHH9twMeta?.path ?? "/legal",
    meta: legalzeOtcHH9twMeta || {},
    alias: legalzeOtcHH9twMeta?.alias || [],
    redirect: legalzeOtcHH9twMeta?.redirect || undefined,
    component: () => import("/workspace/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: legalzeOtcHH9twMeta?.name ?? "legal___fr",
    path: legalzeOtcHH9twMeta?.path ?? "/fr/mentions-legales",
    meta: legalzeOtcHH9twMeta || {},
    alias: legalzeOtcHH9twMeta?.alias || [],
    redirect: legalzeOtcHH9twMeta?.redirect || undefined,
    component: () => import("/workspace/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: legalzeOtcHH9twMeta?.name ?? "legal___br",
    path: legalzeOtcHH9twMeta?.path ?? "/br/mentions-legales",
    meta: legalzeOtcHH9twMeta || {},
    alias: legalzeOtcHH9twMeta?.alias || [],
    redirect: legalzeOtcHH9twMeta?.redirect || undefined,
    component: () => import("/workspace/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: legalzeOtcHH9twMeta?.name ?? "legal___ga",
    path: legalzeOtcHH9twMeta?.path ?? "/ga/mentions-legales",
    meta: legalzeOtcHH9twMeta || {},
    alias: legalzeOtcHH9twMeta?.alias || [],
    redirect: legalzeOtcHH9twMeta?.redirect || undefined,
    component: () => import("/workspace/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: legalzeOtcHH9twMeta?.name ?? "legal___en",
    path: legalzeOtcHH9twMeta?.path ?? "/en/mentions-legales",
    meta: legalzeOtcHH9twMeta || {},
    alias: legalzeOtcHH9twMeta?.alias || [],
    redirect: legalzeOtcHH9twMeta?.redirect || undefined,
    component: () => import("/workspace/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: _91id_93z4WGN65YCKMeta?.name ?? "news-id",
    path: _91id_93z4WGN65YCKMeta?.path ?? "/news/:id()",
    meta: _91id_93z4WGN65YCKMeta || {},
    alias: _91id_93z4WGN65YCKMeta?.alias || [],
    redirect: _91id_93z4WGN65YCKMeta?.redirect || undefined,
    component: () => import("/workspace/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93z4WGN65YCKMeta?.name ?? "news-id___fr",
    path: _91id_93z4WGN65YCKMeta?.path ?? "/fr/actualite/:id",
    meta: _91id_93z4WGN65YCKMeta || {},
    alias: _91id_93z4WGN65YCKMeta?.alias || [],
    redirect: _91id_93z4WGN65YCKMeta?.redirect || undefined,
    component: () => import("/workspace/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93z4WGN65YCKMeta?.name ?? "news-id___br",
    path: _91id_93z4WGN65YCKMeta?.path ?? "/br/actualite/:id",
    meta: _91id_93z4WGN65YCKMeta || {},
    alias: _91id_93z4WGN65YCKMeta?.alias || [],
    redirect: _91id_93z4WGN65YCKMeta?.redirect || undefined,
    component: () => import("/workspace/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93z4WGN65YCKMeta?.name ?? "news-id___ga",
    path: _91id_93z4WGN65YCKMeta?.path ?? "/ga/actualite/:id",
    meta: _91id_93z4WGN65YCKMeta || {},
    alias: _91id_93z4WGN65YCKMeta?.alias || [],
    redirect: _91id_93z4WGN65YCKMeta?.redirect || undefined,
    component: () => import("/workspace/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93z4WGN65YCKMeta?.name ?? "news-id___en",
    path: _91id_93z4WGN65YCKMeta?.path ?? "/en/actualite/:id",
    meta: _91id_93z4WGN65YCKMeta || {},
    alias: _91id_93z4WGN65YCKMeta?.alias || [],
    redirect: _91id_93z4WGN65YCKMeta?.redirect || undefined,
    component: () => import("/workspace/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYTGC2mQSXYMeta?.name ?? "news",
    path: indexYTGC2mQSXYMeta?.path ?? "/news",
    meta: indexYTGC2mQSXYMeta || {},
    alias: indexYTGC2mQSXYMeta?.alias || [],
    redirect: indexYTGC2mQSXYMeta?.redirect || undefined,
    component: () => import("/workspace/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexYTGC2mQSXYMeta?.name ?? "news___fr",
    path: indexYTGC2mQSXYMeta?.path ?? "/fr/actualites",
    meta: indexYTGC2mQSXYMeta || {},
    alias: indexYTGC2mQSXYMeta?.alias || [],
    redirect: indexYTGC2mQSXYMeta?.redirect || undefined,
    component: () => import("/workspace/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexYTGC2mQSXYMeta?.name ?? "news___br",
    path: indexYTGC2mQSXYMeta?.path ?? "/br/actualites",
    meta: indexYTGC2mQSXYMeta || {},
    alias: indexYTGC2mQSXYMeta?.alias || [],
    redirect: indexYTGC2mQSXYMeta?.redirect || undefined,
    component: () => import("/workspace/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexYTGC2mQSXYMeta?.name ?? "news___ga",
    path: indexYTGC2mQSXYMeta?.path ?? "/ga/actualites",
    meta: indexYTGC2mQSXYMeta || {},
    alias: indexYTGC2mQSXYMeta?.alias || [],
    redirect: indexYTGC2mQSXYMeta?.redirect || undefined,
    component: () => import("/workspace/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexYTGC2mQSXYMeta?.name ?? "news___en",
    path: indexYTGC2mQSXYMeta?.path ?? "/en/actualites",
    meta: indexYTGC2mQSXYMeta || {},
    alias: indexYTGC2mQSXYMeta?.alias || [],
    redirect: indexYTGC2mQSXYMeta?.redirect || undefined,
    component: () => import("/workspace/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: partner_45radiosnP956sfu6nMeta?.name ?? "partner-radios",
    path: partner_45radiosnP956sfu6nMeta?.path ?? "/partner-radios",
    meta: partner_45radiosnP956sfu6nMeta || {},
    alias: partner_45radiosnP956sfu6nMeta?.alias || [],
    redirect: partner_45radiosnP956sfu6nMeta?.redirect || undefined,
    component: () => import("/workspace/pages/partner-radios.vue").then(m => m.default || m)
  },
  {
    name: partner_45radiosnP956sfu6nMeta?.name ?? "partner-radios___fr",
    path: partner_45radiosnP956sfu6nMeta?.path ?? "/fr/les-radios-partenaires",
    meta: partner_45radiosnP956sfu6nMeta || {},
    alias: partner_45radiosnP956sfu6nMeta?.alias || [],
    redirect: partner_45radiosnP956sfu6nMeta?.redirect || undefined,
    component: () => import("/workspace/pages/partner-radios.vue").then(m => m.default || m)
  },
  {
    name: partner_45radiosnP956sfu6nMeta?.name ?? "partner-radios___br",
    path: partner_45radiosnP956sfu6nMeta?.path ?? "/br/les-radios-partenaires",
    meta: partner_45radiosnP956sfu6nMeta || {},
    alias: partner_45radiosnP956sfu6nMeta?.alias || [],
    redirect: partner_45radiosnP956sfu6nMeta?.redirect || undefined,
    component: () => import("/workspace/pages/partner-radios.vue").then(m => m.default || m)
  },
  {
    name: partner_45radiosnP956sfu6nMeta?.name ?? "partner-radios___ga",
    path: partner_45radiosnP956sfu6nMeta?.path ?? "/ga/les-radios-partenaires",
    meta: partner_45radiosnP956sfu6nMeta || {},
    alias: partner_45radiosnP956sfu6nMeta?.alias || [],
    redirect: partner_45radiosnP956sfu6nMeta?.redirect || undefined,
    component: () => import("/workspace/pages/partner-radios.vue").then(m => m.default || m)
  },
  {
    name: partner_45radiosnP956sfu6nMeta?.name ?? "partner-radios___en",
    path: partner_45radiosnP956sfu6nMeta?.path ?? "/en/les-radios-partenaires",
    meta: partner_45radiosnP956sfu6nMeta || {},
    alias: partner_45radiosnP956sfu6nMeta?.alias || [],
    redirect: partner_45radiosnP956sfu6nMeta?.redirect || undefined,
    component: () => import("/workspace/pages/partner-radios.vue").then(m => m.default || m)
  },
  {
    name: player_45debug7aeCiAkiBdMeta?.name ?? "player-debug",
    path: player_45debug7aeCiAkiBdMeta?.path ?? "/player-debug",
    meta: player_45debug7aeCiAkiBdMeta || {},
    alias: player_45debug7aeCiAkiBdMeta?.alias || [],
    redirect: player_45debug7aeCiAkiBdMeta?.redirect || undefined,
    component: () => import("/workspace/pages/player-debug.vue").then(m => m.default || m)
  },
  {
    name: player_45debug7aeCiAkiBdMeta?.name ?? "player-debug___fr",
    path: player_45debug7aeCiAkiBdMeta?.path ?? "/fr/player-debug",
    meta: player_45debug7aeCiAkiBdMeta || {},
    alias: player_45debug7aeCiAkiBdMeta?.alias || [],
    redirect: player_45debug7aeCiAkiBdMeta?.redirect || undefined,
    component: () => import("/workspace/pages/player-debug.vue").then(m => m.default || m)
  },
  {
    name: player_45debug7aeCiAkiBdMeta?.name ?? "player-debug___br",
    path: player_45debug7aeCiAkiBdMeta?.path ?? "/br/player-debug",
    meta: player_45debug7aeCiAkiBdMeta || {},
    alias: player_45debug7aeCiAkiBdMeta?.alias || [],
    redirect: player_45debug7aeCiAkiBdMeta?.redirect || undefined,
    component: () => import("/workspace/pages/player-debug.vue").then(m => m.default || m)
  },
  {
    name: player_45debug7aeCiAkiBdMeta?.name ?? "player-debug___ga",
    path: player_45debug7aeCiAkiBdMeta?.path ?? "/ga/player-debug",
    meta: player_45debug7aeCiAkiBdMeta || {},
    alias: player_45debug7aeCiAkiBdMeta?.alias || [],
    redirect: player_45debug7aeCiAkiBdMeta?.redirect || undefined,
    component: () => import("/workspace/pages/player-debug.vue").then(m => m.default || m)
  },
  {
    name: player_45debug7aeCiAkiBdMeta?.name ?? "player-debug___en",
    path: player_45debug7aeCiAkiBdMeta?.path ?? "/en/player-debug",
    meta: player_45debug7aeCiAkiBdMeta || {},
    alias: player_45debug7aeCiAkiBdMeta?.alias || [],
    redirect: player_45debug7aeCiAkiBdMeta?.redirect || undefined,
    component: () => import("/workspace/pages/player-debug.vue").then(m => m.default || m)
  },
  {
    name: _91category_93lFwTm7hna0Meta?.name ?? "programs-category",
    path: _91category_93lFwTm7hna0Meta?.path ?? "/programs/:category()",
    meta: _91category_93lFwTm7hna0Meta || {},
    alias: _91category_93lFwTm7hna0Meta?.alias || [],
    redirect: _91category_93lFwTm7hna0Meta?.redirect || undefined,
    component: () => import("/workspace/pages/programs/[category].vue").then(m => m.default || m)
  },
  {
    name: _91category_93lFwTm7hna0Meta?.name ?? "programs-category___fr",
    path: _91category_93lFwTm7hna0Meta?.path ?? "/fr/emissions/:category",
    meta: _91category_93lFwTm7hna0Meta || {},
    alias: _91category_93lFwTm7hna0Meta?.alias || [],
    redirect: _91category_93lFwTm7hna0Meta?.redirect || undefined,
    component: () => import("/workspace/pages/programs/[category].vue").then(m => m.default || m)
  },
  {
    name: _91category_93lFwTm7hna0Meta?.name ?? "programs-category___br",
    path: _91category_93lFwTm7hna0Meta?.path ?? "/br/emissions/:category",
    meta: _91category_93lFwTm7hna0Meta || {},
    alias: _91category_93lFwTm7hna0Meta?.alias || [],
    redirect: _91category_93lFwTm7hna0Meta?.redirect || undefined,
    component: () => import("/workspace/pages/programs/[category].vue").then(m => m.default || m)
  },
  {
    name: _91category_93lFwTm7hna0Meta?.name ?? "programs-category___ga",
    path: _91category_93lFwTm7hna0Meta?.path ?? "/ga/emissions/:category",
    meta: _91category_93lFwTm7hna0Meta || {},
    alias: _91category_93lFwTm7hna0Meta?.alias || [],
    redirect: _91category_93lFwTm7hna0Meta?.redirect || undefined,
    component: () => import("/workspace/pages/programs/[category].vue").then(m => m.default || m)
  },
  {
    name: _91category_93lFwTm7hna0Meta?.name ?? "programs-category___en",
    path: _91category_93lFwTm7hna0Meta?.path ?? "/en/emissions/:category",
    meta: _91category_93lFwTm7hna0Meta || {},
    alias: _91category_93lFwTm7hna0Meta?.alias || [],
    redirect: _91category_93lFwTm7hna0Meta?.redirect || undefined,
    component: () => import("/workspace/pages/programs/[category].vue").then(m => m.default || m)
  },
  {
    name: indexoo0aTMUTRHMeta?.name ?? "programs",
    path: indexoo0aTMUTRHMeta?.path ?? "/programs",
    meta: indexoo0aTMUTRHMeta || {},
    alias: indexoo0aTMUTRHMeta?.alias || [],
    redirect: indexoo0aTMUTRHMeta?.redirect || undefined,
    component: () => import("/workspace/pages/programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexoo0aTMUTRHMeta?.name ?? "programs___fr",
    path: indexoo0aTMUTRHMeta?.path ?? "/fr/emissions",
    meta: indexoo0aTMUTRHMeta || {},
    alias: indexoo0aTMUTRHMeta?.alias || [],
    redirect: indexoo0aTMUTRHMeta?.redirect || undefined,
    component: () => import("/workspace/pages/programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexoo0aTMUTRHMeta?.name ?? "programs___br",
    path: indexoo0aTMUTRHMeta?.path ?? "/br/emissions",
    meta: indexoo0aTMUTRHMeta || {},
    alias: indexoo0aTMUTRHMeta?.alias || [],
    redirect: indexoo0aTMUTRHMeta?.redirect || undefined,
    component: () => import("/workspace/pages/programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexoo0aTMUTRHMeta?.name ?? "programs___ga",
    path: indexoo0aTMUTRHMeta?.path ?? "/ga/emissions",
    meta: indexoo0aTMUTRHMeta || {},
    alias: indexoo0aTMUTRHMeta?.alias || [],
    redirect: indexoo0aTMUTRHMeta?.redirect || undefined,
    component: () => import("/workspace/pages/programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexoo0aTMUTRHMeta?.name ?? "programs___en",
    path: indexoo0aTMUTRHMeta?.path ?? "/en/emissions",
    meta: indexoo0aTMUTRHMeta || {},
    alias: indexoo0aTMUTRHMeta?.alias || [],
    redirect: indexoo0aTMUTRHMeta?.redirect || undefined,
    component: () => import("/workspace/pages/programs/index.vue").then(m => m.default || m)
  },
  {
    name: schedule8R2ap9OlOoMeta?.name ?? "schedule",
    path: schedule8R2ap9OlOoMeta?.path ?? "/schedule",
    meta: schedule8R2ap9OlOoMeta || {},
    alias: schedule8R2ap9OlOoMeta?.alias || [],
    redirect: schedule8R2ap9OlOoMeta?.redirect || undefined,
    component: () => import("/workspace/pages/schedule.vue").then(m => m.default || m)
  },
  {
    name: schedule8R2ap9OlOoMeta?.name ?? "schedule___fr",
    path: schedule8R2ap9OlOoMeta?.path ?? "/fr/programmation",
    meta: schedule8R2ap9OlOoMeta || {},
    alias: schedule8R2ap9OlOoMeta?.alias || [],
    redirect: schedule8R2ap9OlOoMeta?.redirect || undefined,
    component: () => import("/workspace/pages/schedule.vue").then(m => m.default || m)
  },
  {
    name: schedule8R2ap9OlOoMeta?.name ?? "schedule___br",
    path: schedule8R2ap9OlOoMeta?.path ?? "/br/programmation",
    meta: schedule8R2ap9OlOoMeta || {},
    alias: schedule8R2ap9OlOoMeta?.alias || [],
    redirect: schedule8R2ap9OlOoMeta?.redirect || undefined,
    component: () => import("/workspace/pages/schedule.vue").then(m => m.default || m)
  },
  {
    name: schedule8R2ap9OlOoMeta?.name ?? "schedule___ga",
    path: schedule8R2ap9OlOoMeta?.path ?? "/ga/programmation",
    meta: schedule8R2ap9OlOoMeta || {},
    alias: schedule8R2ap9OlOoMeta?.alias || [],
    redirect: schedule8R2ap9OlOoMeta?.redirect || undefined,
    component: () => import("/workspace/pages/schedule.vue").then(m => m.default || m)
  },
  {
    name: schedule8R2ap9OlOoMeta?.name ?? "schedule___en",
    path: schedule8R2ap9OlOoMeta?.path ?? "/en/programmation",
    meta: schedule8R2ap9OlOoMeta || {},
    alias: schedule8R2ap9OlOoMeta?.alias || [],
    redirect: schedule8R2ap9OlOoMeta?.redirect || undefined,
    component: () => import("/workspace/pages/schedule.vue").then(m => m.default || m)
  },
  {
    name: searchQVlILEhc6OMeta?.name ?? "search",
    path: searchQVlILEhc6OMeta?.path ?? "/search",
    meta: searchQVlILEhc6OMeta || {},
    alias: searchQVlILEhc6OMeta?.alias || [],
    redirect: searchQVlILEhc6OMeta?.redirect || undefined,
    component: () => import("/workspace/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchQVlILEhc6OMeta?.name ?? "search___fr",
    path: searchQVlILEhc6OMeta?.path ?? "/fr/search",
    meta: searchQVlILEhc6OMeta || {},
    alias: searchQVlILEhc6OMeta?.alias || [],
    redirect: searchQVlILEhc6OMeta?.redirect || undefined,
    component: () => import("/workspace/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchQVlILEhc6OMeta?.name ?? "search___br",
    path: searchQVlILEhc6OMeta?.path ?? "/br/search",
    meta: searchQVlILEhc6OMeta || {},
    alias: searchQVlILEhc6OMeta?.alias || [],
    redirect: searchQVlILEhc6OMeta?.redirect || undefined,
    component: () => import("/workspace/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchQVlILEhc6OMeta?.name ?? "search___ga",
    path: searchQVlILEhc6OMeta?.path ?? "/ga/search",
    meta: searchQVlILEhc6OMeta || {},
    alias: searchQVlILEhc6OMeta?.alias || [],
    redirect: searchQVlILEhc6OMeta?.redirect || undefined,
    component: () => import("/workspace/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchQVlILEhc6OMeta?.name ?? "search___en",
    path: searchQVlILEhc6OMeta?.path ?? "/en/search",
    meta: searchQVlILEhc6OMeta || {},
    alias: searchQVlILEhc6OMeta?.alias || [],
    redirect: searchQVlILEhc6OMeta?.redirect || undefined,
    component: () => import("/workspace/pages/search.vue").then(m => m.default || m)
  },
  {
    name: sponsorsKiigEKYNN5Meta?.name ?? "sponsors",
    path: sponsorsKiigEKYNN5Meta?.path ?? "/sponsors",
    meta: sponsorsKiigEKYNN5Meta || {},
    alias: sponsorsKiigEKYNN5Meta?.alias || [],
    redirect: sponsorsKiigEKYNN5Meta?.redirect || undefined,
    component: () => import("/workspace/pages/sponsors.vue").then(m => m.default || m)
  },
  {
    name: sponsorsKiigEKYNN5Meta?.name ?? "sponsors___fr",
    path: sponsorsKiigEKYNN5Meta?.path ?? "/fr/sponsors",
    meta: sponsorsKiigEKYNN5Meta || {},
    alias: sponsorsKiigEKYNN5Meta?.alias || [],
    redirect: sponsorsKiigEKYNN5Meta?.redirect || undefined,
    component: () => import("/workspace/pages/sponsors.vue").then(m => m.default || m)
  },
  {
    name: sponsorsKiigEKYNN5Meta?.name ?? "sponsors___br",
    path: sponsorsKiigEKYNN5Meta?.path ?? "/br/sponsors",
    meta: sponsorsKiigEKYNN5Meta || {},
    alias: sponsorsKiigEKYNN5Meta?.alias || [],
    redirect: sponsorsKiigEKYNN5Meta?.redirect || undefined,
    component: () => import("/workspace/pages/sponsors.vue").then(m => m.default || m)
  },
  {
    name: sponsorsKiigEKYNN5Meta?.name ?? "sponsors___ga",
    path: sponsorsKiigEKYNN5Meta?.path ?? "/ga/sponsors",
    meta: sponsorsKiigEKYNN5Meta || {},
    alias: sponsorsKiigEKYNN5Meta?.alias || [],
    redirect: sponsorsKiigEKYNN5Meta?.redirect || undefined,
    component: () => import("/workspace/pages/sponsors.vue").then(m => m.default || m)
  },
  {
    name: sponsorsKiigEKYNN5Meta?.name ?? "sponsors___en",
    path: sponsorsKiigEKYNN5Meta?.path ?? "/en/sponsors",
    meta: sponsorsKiigEKYNN5Meta || {},
    alias: sponsorsKiigEKYNN5Meta?.alias || [],
    redirect: sponsorsKiigEKYNN5Meta?.redirect || undefined,
    component: () => import("/workspace/pages/sponsors.vue").then(m => m.default || m)
  },
  {
    name: what_45songQ9VUjSQ32XMeta?.name ?? "what-song",
    path: what_45songQ9VUjSQ32XMeta?.path ?? "/what-song",
    meta: what_45songQ9VUjSQ32XMeta || {},
    alias: what_45songQ9VUjSQ32XMeta?.alias || [],
    redirect: what_45songQ9VUjSQ32XMeta?.redirect || undefined,
    component: () => import("/workspace/pages/what-song.vue").then(m => m.default || m)
  },
  {
    name: what_45songQ9VUjSQ32XMeta?.name ?? "what-song___fr",
    path: what_45songQ9VUjSQ32XMeta?.path ?? "/fr/c-etait-quoi-ce-morceau",
    meta: what_45songQ9VUjSQ32XMeta || {},
    alias: what_45songQ9VUjSQ32XMeta?.alias || [],
    redirect: what_45songQ9VUjSQ32XMeta?.redirect || undefined,
    component: () => import("/workspace/pages/what-song.vue").then(m => m.default || m)
  },
  {
    name: what_45songQ9VUjSQ32XMeta?.name ?? "what-song___br",
    path: what_45songQ9VUjSQ32XMeta?.path ?? "/br/c-etait-quoi-ce-morceau",
    meta: what_45songQ9VUjSQ32XMeta || {},
    alias: what_45songQ9VUjSQ32XMeta?.alias || [],
    redirect: what_45songQ9VUjSQ32XMeta?.redirect || undefined,
    component: () => import("/workspace/pages/what-song.vue").then(m => m.default || m)
  },
  {
    name: what_45songQ9VUjSQ32XMeta?.name ?? "what-song___ga",
    path: what_45songQ9VUjSQ32XMeta?.path ?? "/ga/c-etait-quoi-ce-morceau",
    meta: what_45songQ9VUjSQ32XMeta || {},
    alias: what_45songQ9VUjSQ32XMeta?.alias || [],
    redirect: what_45songQ9VUjSQ32XMeta?.redirect || undefined,
    component: () => import("/workspace/pages/what-song.vue").then(m => m.default || m)
  },
  {
    name: what_45songQ9VUjSQ32XMeta?.name ?? "what-song___en",
    path: what_45songQ9VUjSQ32XMeta?.path ?? "/en/c-etait-quoi-ce-morceau",
    meta: what_45songQ9VUjSQ32XMeta || {},
    alias: what_45songQ9VUjSQ32XMeta?.alias || [],
    redirect: what_45songQ9VUjSQ32XMeta?.redirect || undefined,
    component: () => import("/workspace/pages/what-song.vue").then(m => m.default || m)
  }
]